import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    TextField, Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@mui/material';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import ImageModal from '../modals/ImageModal';
import UploadModal from '../modals/UploadModal';
import { useNavigate } from 'react-router-dom';

function GoodsTable() {
    const [goodsList, setGoodsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const initialFilters = {
        status: '',
        startDate: '', // Add startDate and endDate fields
        endDate: '', // Add startDate and endDate fields
        countryOrigin: '',
        countryDestination: '',
        clientName: '',
        clientTel: '',
        forwarderName: '',
        forwarderTel: '',
        shippingMode: '', // New filter for shipping mode
    };

    const navigate = useNavigate(); // useNavigate hook

    const [modalGoodsId, setModalGoodsId] = useState(null); // Store the goodsID for the modal
    const [modalGoodsText, setModalGoodsText] = useState(null); // Store the goodsID for the modal

    const [showFilters, setShowFilters] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for upload modal


    const handleImageClick = (imageUrl, rowId) => {
        setSelectedImage(imageUrl);
        setSelectedRowId(rowId);
        console.log(`rowId ${rowId}`);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        setSelectedRowId(null);
    };


    const [filters, setFilters] = useState(initialFilters);

    const [isEditing, setIsEditing] = useState(false);
    const [editedGoods, setEditedGoods] = useState({});

    const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);

    const toggleEdit = (goodsId) => {
        setCurrentlyEditedRow(goodsId === currentlyEditedRow ? null : goodsId);
        setEditedGoods({ ...editedGoods, [goodsId]: {} });
    };

    // const handleEditChange = (goodsId, field, value) => {
    //     setEditedGoods((prevEditedGoods) => ({
    //         ...prevEditedGoods,
    //         [goodsId]: {
    //             ...prevEditedGoods[goodsId],
    //             [field]: value,
    //         },
    //     }));
    // };

    // Function to cancel the edit and revert to the original values
    const cancelEdit = (rowId) => {
        // Remove the row from the editedGoods state
        const newEditedGoods = { ...editedGoods };
        delete newEditedGoods[rowId];
        setEditedGoods(newEditedGoods);

        // Reset the currentlyEditedRow state
        setCurrentlyEditedRow(null);
    };

    const handleDateChange = (goodsId, field, date) => {
        setEditedGoods((prevEditedGoods) => ({
            ...prevEditedGoods,
            [goodsId]: {
                ...prevEditedGoods[goodsId],
                [field]: date,
            },
        }));
    };

    const testID = (goodsID) => {
        console.log('testID');
        console.log(goodsID);
    };
    const handleUploadButtonClick = (goodsID,targetImageTitle) => {
        setModalGoodsId(goodsID); // Set the goodsID for the modal
        setModalGoodsText(targetImageTitle); //
        setIsUploadModalOpen(true); // Open the upload modal
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false); // Close the upload modal
    };

    const handleEditChange = (goodsId, field, value) => {
        setEditedGoods((prevEditedGoods) => ({
            ...prevEditedGoods,
            [goodsId]: {
                ...prevEditedGoods[goodsId],
                [field]: value,
            },
        }));
    };

    // const saveEditedGoods = async (goodsId) => {
    //     const authToken = localStorage.getItem('token');
    //     const editedData = editedGoods[goodsId];

    //     console.log(editedData); // Log the data before the asynchronous update
    //     console.log(goodsId); // Log the data before the asynchronous update

    //     try {
    //         await axios.put(`${process.env.REACT_APP_BASE_URL}/goods-tracking/updateByAdmin/${goodsId}`, editedData, {
    //             headers: {
    //                 Authorization: `Bearer ${authToken}`,
    //             },
    //         });

    //         // Toggle off editing mode
    //         toggleEdit();

    //         // No need to update local state as we are directly updating the edited document
    //     } catch (error) {
    //         console.error('Error updating goods:', error);
    //     }
    // };

    const saveEditedGoods = async (goodsId) => {
        const authToken = localStorage.getItem('token');
        const editedData = editedGoods[goodsId];

        console.log(`editedData ${JSON.stringify(editedData)}`);
        console.log(`goodsId ${JSON.stringify(goodsId)}`);

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/goods-tracking/updateByAdmin/${goodsId}`, {
                ...editedData,
                goodsTrackingID: goodsId, // Ensure goodsTrackingID is included in the request
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            fetchData();

            // Toggle off editing mode
            toggleEdit();

            // No need to update local state as we are directly updating the edited document
        } catch (error) {
            console.error('Error updating goods:', error);
        }
    };




    // Function to reset filters to their initial state
    const handleResetFilters = () => {
        setFilters(initialFilters);
    };


    const [updateTable, setUpdateTable] = useState(0)


    
   


    const fetchData = async () => {
        try {
            const authToken = localStorage.getItem('token');

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/goods-tracking/displayAll`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            console.log(`response.data ${JSON.stringify(response.data)}`);

            if (response.status === 200) {
                const formattedGoodsList = response.data.map((goods) => ({
                    _id: goods._id,
                    goodsName: goods.goodsName || '',
                    goodsDescription: goods.goodsDescription || '',
                    goodsCountryOrigin: goods.goodsCountryOrigin || '',
                    goodsCountryDestination: goods.goodsCountryDestination || '',
                    goodsType: goods.goodsType || '',
                    goodsQuantity: goods.goodsQuantity || 0,
                    goodsTotalWeight: goods.goodsTotalWeight || 0,
                    goodsTotalCBM: goods.goodsTotalCBM || 0,
                    SuplierGoodsTotalWeight: goods.SuplierGoodsTotalWeight || 0,
                    goodsImagesLinks: goods.goodsImagesLinks || [],
                    goodsShippingMode: goods.goodsShippingMode || '',
                    goodsCurrentCountrylocation: goods.goodsCurrentCountrylocation || '',
                    clientName: goods.clientName || '',
                    clientTel: goods.clientTel || '',
                    clientAddress: goods.clientAddress || '',
                    clientTotalToPaidAmount: goods.clientTotalToPaidAmount || 0,
                    clientPaidAmount: goods.clientPaidAmount || 0,
                    clientPaymentStatus: goods.clientPaymentStatus || '',
                    clientReceptImagesLinks: goods.clientReceptImagesLinks || [],
                    status: goods.status || '',
                    forwarderName: goods.forwarderName || '',
                    forwarderTel: goods.forwarderTel || '',
                    forwarderPaymentAmount: goods.forwarderPaymentAmount || 0,
                    forwarderPaidAmount: goods.forwarderPaidAmount || 0,
                    forwarderPaymentStatus: goods.forwarderPaymentStatus || '',
                    forwarderReceptImagesLinks: goods.forwarderReceptImagesLinks || [],
                    adminComment: goods.adminComment || '',
                    client: goods.client || '',
                    deliveredToClientDate: goods.deliveredToClientDate || '',
                    expeditionDate: goods.expeditionDate || '',
                    receptionInWareHouseDate: goods.receptionInWareHouseDate || '',
                    goodsCreationdate: formatDate(goods.goodsCreationdate),
                    senderReceptImagesLinks: goods.senderReceptImagesLinks || [],
                }));

                // Sort the formattedGoodsList based on goodsCreationdate
                formattedGoodsList.sort((a, b) => new Date(b.goodsCreationdate) - new Date(a.goodsCreationdate));

                setGoodsList(formattedGoodsList);

                console.log(`goodsList ${goodsList}`);
                setIsLoading(false);
            } else {
                console.error('Error fetching goods:', response.statusText);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching goods:', error);
            setIsLoading(false);
            // Handle error and redirection here
        }
    };

    useEffect(() => {
        fetchData();
    }, [updateTable]);

    // // Function to format date to "dd/mm/yy"
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const day = date.getDate().toString().padStart(2, '0');
    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //     const year = date.getFullYear().toString().slice(-2);
    //     return `${day}/${month}/${year}`;
    // };

    const formatDate = (date) => {
        // Format a Date object to 'YYYY-MM-DD'
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };




    const handleDeleteGoods = async (goodsId) => {
        try {
            const accessToken = localStorage.getItem('token');
            if (!accessToken) {
                throw new Error('No access token found in localStorage');
            }

            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/goods-tracking/delete/${goodsId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {

                // Reload the current page after a successful delete
                window.location.reload();
                // Remove the deleted item from the goodsList
                setGoodsList((prevGoodsList) => prevGoodsList.filter((goods) => goods.id !== goodsId));
            } else {
                console.error('Failed to delete goods:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting goods:', error);
        }
    };

    

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });
    };

   

    const filteredGoods = goodsList.filter((goods) => {
        const {
            _id,
            status,
            startDate,
            endDate,
            countryOrigin,
            countryDestination,
            clientName,
            clientTel,
            forwarderName,
            forwarderTel,
            shippingMode,
        } = filters;

        const goodsDate = new Date(goods.goodsCreationdate);

        const idFilterMatch = !_id || goods._id.toString().includes(_id.toString());

        return (
            idFilterMatch &&
            (status === '' || goods.status === status) &&
            (!startDate || new Date(startDate) <= goodsDate) && // Adjusted date comparison
            (!endDate || new Date(endDate) >= goodsDate) &&     // Adjusted date comparison
            (countryOrigin === '' || goods.goodsCountryOrigin === countryOrigin) &&
            (countryDestination === '' || goods.goodsCountryDestination === countryDestination) &&
            (clientName === '' || goods.clientName.includes(clientName)) &&
            (clientTel === '' || goods.clientTel.includes(clientTel)) &&
            (forwarderName === '' || goods.forwarderName.includes(forwarderName)) &&
            (forwarderTel === '' || goods.forwarderTel.includes(forwarderTel)) &&
            (shippingMode === '' || goods.goodsShippingMode === shippingMode)
        );
    });


    if (isLoading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
        );
    }



    const generateExcelData = () => {
        const data = [];
        data.push([
            'Goods ID',
            'Goods Name',
            'Goods Status',
            'Goods Description',
            'Goods Type',
            'Goods Quantity',
            'Country Origin',
            'Country Destination',
            'Total Weight',
            'Total CBM',
            'Shipping Mode',
            'Current Location',
            'Client Name',
            'Client Tel',
            'Client Address',
            'Client Paid Amount',
            'Client Payment Status',
            'forwarder Name',
            'forwarder Tel',
            'forwarder Payment Amount',
            'forwarder Payment Status',
            'Admin Comment',
            'forwarder Comment',
            'Client',
            'Date',
            'Delivery Date',
            'Reception Date',
            'Suplier Goods TotalWeight',
        ]);

        filteredGoods.forEach((goods) => {
            data.push([
                goods._id.slice(-6), // Extract the last 6 digits of the ID
                goods.goodsName,
                goods.status,
                goods.goodsDescription,
                goods.goodsType,
                goods.goodsQuantity,
                goods.goodsCountryOrigin,
                goods.goodsCountryDestination,
                goods.goodsTotalWeight,
                goods.goodsTotalCBM,
                goods.goodsShippingMode,
                goods.goodsCurrentCountrylocation,
                goods.clientName,
                goods.clientTel,
                goods.clientAddress,
                goods.clientPaidAmount,
                goods.clientPaymentStatus,
                goods.forwarderName,
                goods.forwarderTel,
                goods.forwarderPaymentAmount,
                goods.forwarderPayementStatus,
                goods.adminComment,
                goods.forwarderComment,
                goods.client,
                goods.goodsCreationdate,
                goods.deliveredToClientDate,
                goods.expeditionDate,
                goods.receptionInWareHouseDate,
                goods.SuplierGoodsTotalWeight,
            ]);
        });

        return data;
    };

    const handleDownloadExcel = () => {
        const data = generateExcelData();
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Expenses');
        XLSX.writeFile(wb, 'expenses.xlsx');
    
    };



    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };
   
    const handleGoToNewGoodsForm = () => {
        navigate('/goodsForm')
    }

    function formatCurrency(amount) {
        return amount.toLocaleString('en-US', {
            style: 'decimal',
            // currency: 'GNF',
            minimumFractionDigits: 2,
        });
    }


    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };


    return (
        <div style={{ margin: '0 auto', maxWidth: '1600px' }}>

            <Typography variant="h2" style={{ 
                fontSize: '3.5rem', 
                fontWeight: 'bold', 
                margin: '12px auto',
                textAlign: 'center',
            }}
                >

                Track Colis 📦
            </Typography>

            <Button
                fullWidth
                style={{
                    backgroundColor: 'green',
                    color: 'white',
                    // margin: '10px 0 10px 0',
                    width: '250px',
                    margin: '20px auto',
                }}
                onClick={handleGoToNewGoodsForm}
            >
                Nouveau Colis ?
            </Button>
            <br />
            <Button
                variant="contained"
                onClick={toggleFilters}
                style={{
                    backgroundColor: 'blue',
                    color: 'white',
                    minWidth: '120px',
                    marginBottom: '10px',
                }}
            >
                {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>


            {showFilters && (
                <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>


                    <FormControl style={{ minWidth: '150px', flex: '1' }}>
                        {/* <InputLabel>Goods ID</InputLabel> */}
                        <TextField
                            label="Goods ID"
                            name="_id"
                            value={filters._id}
                            onChange={handleFilterChange}
                        />
                    </FormControl>

                    <FormControl style={{ minWidth: '120px', marginLeft: '10px' }}>
                        <InputLabel
                            style={{
                                backgroundColor: 'white',
                                padding: '0px 12px 0px 12px',
                            }}
                        >Status</InputLabel>
                        <Select
                            name="status"
                            value={filters.status}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="received in China warehouse">received in China warehouse</MenuItem>
                            <MenuItem value="on the way">on the way</MenuItem>
                            <MenuItem value="under CC">under CC</MenuItem>
                            <MenuItem value="In Guinea warehouse">In Guinea warehouse</MenuItem>
                            <MenuItem value="ready">ready</MenuItem>
                            <MenuItem value="Received By Client">Received By Client</MenuItem>

                        </Select>
                    </FormControl>
                    <FormControl style={{ minWidth: '150px', flex: '1' }}>
                        <InputLabel
                            style={{
                                backgroundColor: 'white',
                                padding: '0px 12px 0px 12px',
                            }}
                        >Shipping Mode</InputLabel>
                        <Select
                            name="shippingMode"
                            value={filters.shippingMode}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="By Air">By Air</MenuItem>
                            <MenuItem value="By Sea">By Sea</MenuItem>
                            <MenuItem value="Road">Road</MenuItem>
                            {/* Add more shipping mode options as needed */}
                        </Select>
                    </FormControl>




                    <FormControl style={{ minWidth: '150px', flex: '1' }}>
                        <InputLabel
                            style={{
                                backgroundColor: 'white',
                                padding: '0px 12px 0px 12px',
                              width: '129px'

                            }}
                        >Country Of  Origin</InputLabel>
                        <Select
                            name="countryOrigin"
                            value={filters.countryOrigin}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="China">China</MenuItem>
                            <MenuItem value="Guinea">Guinea</MenuItem>
                            {/* Add more country options as needed */}
                        </Select>
                    </FormControl>

                    <FormControl style={{ minWidth: '150px', flex: '1' }}>
                        <InputLabel
                            style={{
                                backgroundColor: 'white',
                                padding: '0px 12px 0px 12px',
                            }}
                        >Country Of Destination</InputLabel>
                        <Select
                            name="countryDestination"
                            value={filters.countryDestination}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="China">China</MenuItem>
                            <MenuItem value="Guinea">Guinea</MenuItem>
                            {/* Add more country options as needed */}
                        </Select>
                    </FormControl>






                    <FormControl style={{ minWidth: '150px', flex: '1' }}>
                        {/* <InputLabel>Client Name</InputLabel> */}
                        <TextField
                            label="Client Name"

                            name="clientName"
                            value={filters.clientName}
                            onChange={handleFilterChange}
                        />
                    </FormControl>

                    <FormControl style={{ minWidth: '150px', flex: '1' }}>
                        {/* <InputLabel>Client Tel</InputLabel> */}
                        <TextField
                            label="client Tel"

                            name="clientTel"
                            value={filters.clientTel}
                            onChange={handleFilterChange}
                        />
                    </FormControl>

                    <FormControl style={{ minWidth: '150px', flex: '1' }}>
                        {/* <InputLabel>forwarder Name</InputLabel> */}
                        <TextField
                            label='forwarder Name'
                            name="forwarderName"
                            value={filters.forwarderName}
                            onChange={handleFilterChange}
                        />
                    </FormControl>

                    <FormControl style={{ minWidth: '150px', flex: '1' }}>
                        {/* <InputLabel>forwarder Tel</InputLabel> */}
                        <TextField
                            label='forwarder Tel'

                            name="forwarderTel"
                            value={filters.forwarderTel}
                            onChange={handleFilterChange}
                        />


                    </FormControl>


                    <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>

                        <FormControl style={{ minWidth: '150px', flex: '1' }}>
                            <p>Fitler Start Date</p>
                            <TextField
                                name="startDate"
                                type="date"
                                value={filters.startDate}
                                onChange={handleFilterChange}
                            />
                        </FormControl>

                        <FormControl style={{ minWidth: '150px', flex: '1' }}>
                            <p> Filter End Date</p>
                            <TextField
                                name="endDate"
                                type="date"
                                value={filters.endDate}
                                onChange={handleFilterChange}
                            />
                        </FormControl>

                    </div>


                    <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'darkred', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}
                            onClick={handleResetFilters}
                        >
                            Reset Filters
                        </Button>

                        <Button
                            variant="contained"
                            onClick={handleDownloadExcel}
                            style={{ backgroundColor: 'primary', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}

                        >
                            Download
                        </Button>

                        <Button
                            variant="contained"
                            // onClick={}
                            style={{ backgroundColor: 'green', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}

                        >
                            Upload
                        </Button>

                    </div>




                </div>

            )}

 


                

         

        



           
        <div> <h3>Total {filteredGoods.length} </h3> </div>

            {/* Upload modal */}
            {isUploadModalOpen && (
                <UploadModal goodsID={modalGoodsId}
                    setUpdateTables={ setUpdateTable }

                modalGoodsText={modalGoodsText} onClose={handleUploadModalClose} />
            )}
            {selectedImage && (
                <ImageModal
                style={{
                        zIndex: 9999,

                }}
                imageUrl={selectedImage} 
                    goodsID={ selectedRowId }
                    setUpdateTables={ setUpdateTable}
                
                onClose={handleCloseModal} />
            )}
        <TableContainer 
        style={{
            height: '750px'
        }}
        component={Paper}>
            <Table>
                    <TableHead
                        style={{
                            backgroundColor: '#78AEF9',
                            position: 'sticky',
                            top: 0,
                            zIndex: 99,
                            fontWeight: 'bold', // Apply bold font
                        }}
                    >
                    <TableRow>

                            <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Goods ID</TableCell>

                            <TableCell
                                style={ {
                                    fontSize: '0.96rem',
                                    fontFamily: 'monospace',
                                    fontWeight: 'bold',
                                } }
                            >Date Creation</TableCell>

                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Client Name</TableCell>
                     


                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Goods Images</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Goods Status</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Goods Description</TableCell>
                        {/* <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Goods Type</TableCell> */}
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Quantity of Boxes</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Country  Of Origin</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Country Of Destination</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Total Weight</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Total CBM</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Shipping Mode</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Current Location</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Client Tel</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Client Address</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Client Paid Amount</TableCell>
                       <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                       >Client Payment Status</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Client Receipt</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >forwarder Name</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >forwarder Tel</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >forwarder Total Weight</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >forwarder Payment Amount</TableCell>
                            <TableCell 
                             style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                            >forwarder Payment Status</TableCell>
                            <TableCell 
                             style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                            >forwarder Receipt</TableCell>
                       
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        > Margin</TableCell>

                            <TableCell
                                style={ {
                                    fontSize: '0.96rem',
                                    fontFamily: 'monospace',
                                    fontWeight: 'bold',
                                } }
                            > Remarks</TableCell>


                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >forwarder Comment</TableCell>
                        
                        {/* <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Client</TableCell> */}
                        


                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Expediation Date (Guinea)</TableCell>
                        <TableCell 
                         style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                        >Collection Date in WareHouse (China)</TableCell>
                            <TableCell 
                             style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
                            > Edit</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                      
                      

                        {filteredGoods.map((goods,index) => (
                          

                                <TableRow
                                    key={goods._id}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? '#ADD8E6' : '#73c3e6', // Use the color codes
                                    }}
                                >
                                <TableCell>{ index + 1 }</TableCell>
                                {/* <TableCell>{ goods.goodsCreationdate }</TableCell>  */}


                                <TableCell
                                    style={ {
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    } }
                                >
                                    { currentlyEditedRow === goods._id ? (
                                        <TextField
                                            type='date'
                                            value={ editedGoods[goods._id]?.goodsCreationdate }
                                            onChange={ (e) =>
                                                handleEditChange(goods._id, 'goodsCreationdate', e.target.value)
                                            }
                                        />
                                    ) : (
                                                formatDateToFrench(goods.goodsCreationdate)


                                    ) }
                                </TableCell>



                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.clientName || goods.clientName}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'clientName', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.clientName
                                    )}
                                </TableCell>

                             
                                <TableCell>
                                    <div style={{ whiteSpace: 'nowrap' }}>
                                        
                                     
                                        {currentlyEditedRow === goods._id ? (
                                       
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUploadButtonClick(goods._id, 'goodsImagesLinks')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                                >
                                                    + 
                                                </Button>


                                        ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUploadButtonClick(goods._id, 'goodsImagesLinks')}
                                                    style={{
                                                        borderRadius: '50%',  // Make the button round
                                                        width: '30px',        // Set the button width
                                                        height: '30px',       // Set the button height
                                                        padding: '0',         // Remove padding
                                                        minWidth: 'unset',    // Remove minWidth
                                                    }}
                                                >
                                                    + 
                                                </Button>

                                        )}
                                            {goods.goodsImagesLinks.map((imageLink, index) => (

                                                <img
                                                    key={index}
                                                    src={imageLink}
                                                    alt={`Image ${index}`}
                                                    style={{
                                                        display: 'inline-block',
                                                        maxWidth: '43px',
                                                        maxHeight: '43px',
                                                        marginRight: '5px',
                                                        borderRadius: '50px', // Set your desired border radius
                                                        marginTop: '0',      // Remove marginTop
                                                        marginBottom: '0'    // Remove marginBottom
                                                    }}
                                                    onClick={() => handleImageClick(imageLink,goods._id)}
                                                />

                                            ))}
                                    </div>
                                 
                                </TableCell>
                              



                                


                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.status || goods.status}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'status', e.target.value)
                                                }
                                            >
                                                <MenuItem value="Received By Client">Received By Client</MenuItem>
                                                <MenuItem value="received in China warehouse">received in China warehouse</MenuItem>
                                                <MenuItem value="on the way">on the way</MenuItem>
                                                <MenuItem value="under CC">under CC</MenuItem>
                                                <MenuItem value="In Guinea warehouse">In Guinea warehouse</MenuItem>
                                                <MenuItem value="ready">ready</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.status
                                    )}
                                </TableCell>


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsDescription ||  goods.goodsDescription }
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsDescription', e.target.value)
                                            }
                                        />
                                    ) : (
                                            goods.goodsDescription
                                    )}
                                </TableCell>


 


                                <TableCell
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsQuantity || goods.goodsQuantity}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsQuantity', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsQuantity
                                    )}
                                </TableCell>


                                <TableCell
                                
                                >
                                    <div
                                    
                                        style={ {
                                            width: '89px'

                                        } }>
                                        { currentlyEditedRow === goods._id ? (
                                            <FormControl

                                            >
                                                <InputLabel>Status</InputLabel>
                                                <Select

                                                    value={ editedGoods[goods._id]?.goodsCountryOrigin || goods.goodsCountryOrigin }
                                                    onChange={ (e) =>
                                                        handleEditChange(goods._id, 'goodsCountryOrigin', e.target.value)
                                                    }
                                                >
                                                    <MenuItem value="China">China</MenuItem>
                                                    <MenuItem value="Guinea">Guinea</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            goods.goodsCountryOrigin
                                        ) }


                                    </div>
                                  
                                </TableCell>


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsCountryDestination || goods.goodsCountryDestination}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsCountryDestination', e.target.value)
                                                }
                                            >
                                                <MenuItem value="China">China</MenuItem>
                                                <MenuItem value="Guinea">Guinea</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsCountryDestination
                                    )}
                                </TableCell>


                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsTotalWeight || goods.goodsTotalWeight}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsTotalWeight', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsTotalWeight
                                    )}
                                </TableCell>


                                <TableCell
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsTotalCBM || goods.goodsTotalCBM}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsTotalCBM', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsTotalCBM
                                    )}
                                </TableCell>

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Shipping Mode</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsShippingMode || goods.goodsShippingMode}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsShippingMode', e.target.value)
                                                }
                                            >
                                                <MenuItem value="By Sea">By Sea</MenuItem>
                                                <MenuItem value="By Air">By Air</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsShippingMode
                                    )}
                                </TableCell>


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Current Location</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsCurrentCountrylocation || goods.goodsCurrentCountrylocation}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsCurrentCountrylocation', e.target.value)
                                                }
                                            >
                                                <MenuItem value="China">China</MenuItem>
                                                <MenuItem value="Guinea">Guinea</MenuItem>
                                                <MenuItem value="On the Way">On the Way</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsCurrentCountrylocation
                                    )}
                                </TableCell>

                              

                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.clientTel || goods.clientTel}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'clientTel', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.clientTel
                                    )}
                                </TableCell>

                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.clientAddress || goods.clientAddress}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'clientAddress', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.clientAddress
                                    )}
                                </TableCell>

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>


                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.clientPaidAmount || goods.clientPaidAmount}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'clientPaidAmount', e.target.value)
                                            }
                                        />
                                    ) : (
                                        `${formatCurrency(goods.clientPaidAmount)}`
                                    )}
                                </TableCell>

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Paid ?</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.clientPaymentStatus || goods.clientPaymentStatus}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'clientPaymentStatus', e.target.value)
                                                }
                                            >
                                                <MenuItem value="Not-Paid">Not-Paid</MenuItem>
                                                <MenuItem value="Paid">Paid</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.clientPaymentStatus
                                        
                                    )}
                                </TableCell>

                                <TableCell>
                                    <div style={{ whiteSpace: 'nowrap' }}>


                                        {currentlyEditedRow === goods._id ? (

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleUploadButtonClick(goods._id, 'goodsImagesLinks')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                            >
                                                +
                                            </Button>


                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                    onClick={() => handleUploadButtonClick(goods._id, 'clientReceptImagesLinks')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                            >
                                                +
                                            </Button>

                                        )}
                                        {goods.clientReceptImagesLinks.map((imageLink, index) => (

                                            <img
                                                key={index}
                                                src={imageLink}
                                                alt={`Image ${index}`}
                                                style={{
                                                    display: 'inline-block',
                                                    maxWidth: '43px',
                                                    maxHeight: '43px',
                                                    marginRight: '5px',
                                                    borderRadius: '50px', // Set your desired border radius
                                                    marginTop: '0',      // Remove marginTop
                                                    marginBottom: '0'    // Remove marginBottom
                                                }}
                                                onClick={() => handleImageClick(imageLink,goods._id)}
                                            />

                                        ))}
                                    </div>

                                </TableCell>

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.forwarderName || goods.forwarderName}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'forwarderName', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.forwarderName
                                    )}
                                </TableCell>


                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.forwarderTel || goods.forwarderTel}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'forwarderTel', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.forwarderTel
                                    )}
                                </TableCell>

                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.SuplierGoodsTotalWeight || goods.SuplierGoodsTotalWeight}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'SuplierGoodsTotalWeight', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.SuplierGoodsTotalWeight
                                    )}
                                </TableCell>

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}> 
                                    
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.forwarderPaymentAmount || goods.forwarderPaymentAmount}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'forwarderPaymentAmount', e.target.value)
                                            }
                                        />
                                    ) : (
                                                `${formatCurrency(goods.forwarderPaymentAmount)}`

                                    )}
                                </TableCell>

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>forwarder Paid?</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.forwarderPayementStatus || goods.forwarderPayementStatus}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'forwarderPayementStatus', e.target.value)
                                                }
                                            >
                                                <MenuItem value="Not-Paid">Not-Paid</MenuItem>
                                                <MenuItem value="Paid">Paid</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.forwarderPayementStatus
                                    )}
                                </TableCell>
                                <TableCell>
                                    <div style={{ whiteSpace: 'nowrap' }}>


                                        {currentlyEditedRow === goods._id ? (

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleUploadButtonClick(goods._id, 'goodsImagesLinks')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                            >
                                                +
                                            </Button>


                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                    onClick={() => handleUploadButtonClick(goods._id, 'forwarderReceptImagesLinks')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                            >
                                                +
                                            </Button>

                                        )}
                                        {goods.forwarderReceptImagesLinks.map((imageLink, index) => (

                                            <img
                                                key={index}
                                                src={imageLink}
                                                alt={`Image ${index}`}
                                                style={{
                                                    display: 'inline-block',
                                                    maxWidth: '43px',
                                                    maxHeight: '43px',
                                                    marginRight: '5px',
                                                    borderRadius: '50px', // Set your desired border radius
                                                    marginTop: '0',      // Remove marginTop
                                                    marginBottom: '0'    // Remove marginBottom
                                                }}
                                                onClick={() => handleImageClick(imageLink,goods._id)}
                                            />

                                        ))}
                                    </div>

                                </TableCell>
                                <TableCell>
                                    {/* { formatCurrency(goods.clientPaidAmount + goods.forwarderPaidAmount) } */}
                                    {formatCurrency( goods.clientPaidAmount - (goods.forwarderPaymentAmount) ) }
                                    {/* { formatCurrency(goods.forwarderPaidAmount - goods.clientPaidAmount)} */}
                                </TableCell>


                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.adminComment || goods.adminComment}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'adminComment', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.adminComment
                                    )}
                                </TableCell>

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.forwarderComment || goods.forwarderComment}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'forwarderComment', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.forwarderComment
                                    )}
                                </TableCell>


                                {/* <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.client || goods.client}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'client', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.client
                                    )}
                                </TableCell> */}


                                

                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.expeditionDate || goods.expeditionDate}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'expeditionDate', e.target.value)
                                            }
                                        />
                                    ) : (
                                            formatDateToFrench(goods.expeditionDate)
                                    )}
                                </TableCell>

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.receptionInWareHouseDate || goods.receptionInWareHouseDate}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'receptionInWareHouseDate', e.target.value)
                                            }
                                        />
                                    ) : (
                                                formatDateToFrench(goods.receptionInWareHouseDate)

                                    )}
                                </TableCell>



                                


                                {/* Repeat the same pattern for other columns */}
                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (

                                        <>
                                            <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => saveEditedGoods(goods._id)}
                                        >
                                            Save
                                        </Button>


                                            <Button
                                                variant="contained"
                                                onClick={() => cancelEdit(goods._id)}
                                                style={{ backgroundColor: 'darkred', color: 'white' , marginTop: '5px' }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                style={{ backgroundColor: 'red' }}
                                                onClick={() => {
                                                    if (window.confirm('Are you sure you want to delete this goods?')) {
                                                        handleDeleteGoods(goods._id);
                                                    }
                                                }}
                                            >
                                                Delete
                                            </Button>

                                        </>
                                      

                                        
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => toggleEdit(goods._id)}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </TableCell>

                             
                            </TableRow>
                        ))}
                      
                </TableBody>
                
            </Table>
              
        </TableContainer>
      
        </div>
    );
}

export default GoodsTable;