import React, { useState,useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    TextField,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select, Typography,
    MenuItem,
    Box, Stack, CircularProgress
} from '@mui/material';

import { useNavigate } from 'react-router-dom';


import ListIcon from '@mui/icons-material/List';
import Fab from '@mui/material/Fab';


import Dropzone from 'react-dropzone';
import { Image, Video } from 'cloudinary-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const initialFormData = {
    goodsName: '',
    goodsDescription: '',
    goodsCountryOrigin: 'China',
    goodsCountryDestination: 'Guinea',
    goodsType: '',
    goodsQuantity: 0,
    goodsTotalWeight: 0,
    goodsTotalCBM: 0,
    goodsImagesLinks: [],
    goodsShippingMode: '',
    goodsCurrentCountrylocation: 'China',
    clientName: '',
    clientTel: '',
    clientAddress: '',
    clientPaidAmount: 0,
    clientPaymentStatus: '',
    clientReceptImagesLinks: [],
    status: '',
    forwarderName: '',
    forwarderTel: '',
    forwarderPaymentAmount: 0,
    forwarderPayementStatus: '',
    forwarderReceptImagesLinks: [],
    adminComment: '',
    forwarderComment: '',
    client: '',
    receptionInWareHouseDate: '',
    expeditionDate: '',
    pricePerCMB: '',
    pricePerKg: '',
    SuplierGoodsTotalWeight: 0,
    SupplierpricePerCMB: '',
    SupplierpricePerKg: ''
};

function GoodsForm() {
    const [formData, setFormData] = useState(initialFormData);
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false); 
    

   
    // useEffect(() => {
    //     const userRole = localStorage.getItem('userRole');
    //     if (userRole && userRole !== 'admin') {
    //         window.location.href = '/goodsTableForUser';

    //     }
    // }, []);


    const handleSubmit = async () => {
        // console.log(formData);

        try {
            setIsSubmitting(true);
            const authToken = localStorage.getItem('token');
            const authorizationHeader = `Bearer ${authToken}`;

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/goods-tracking/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorizationHeader,
                },
                body: JSON.stringify(formData), // JSON.stringify the body
            });

            
            const data = await response.json();

            if (response.ok) {
                // Handle success
                toast.success('Colis Submitted Successfully', {
                    position: 'top-center',
                    autoClose: 3000, // 3 seconds
                });
                // console.log('Colis submitted successfully:', data);
                setFormData(initialFormData);

                // Delay the navigation by 3 seconds
                setTimeout(() => {
                    navigate('/goodsTable', { replace: true });
                }, 3000);

                // Handle success, e.g., show a success message or redirect
                console.log('Form submitted successfully');
            } else {
                toast.error('Failed to Submit', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                console.error('Failed to POST NEW GOODS');
                setIsSubmitting(false);
            }


           
        } catch (error) {
            // Handle error, e.g., show an error message
            console.error('Error submitting form:', error);
            setIsSubmitting(false);
        }
    };








    const [uploadedImagesgoodsImagesLinks, setUploadedImagesgoodsImagesLinks] = useState([]);



    const cldNme = `${process.env.REACT_APP_CLOUDINARY_CLDNME_URL}`;


    const handleImageUploadgoodsImagesLinks = async (files) => {
        try {
            const uploadPromises = files.map((file) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', `${process.env.REACT_APP_CLOUDINARY_PRESET}`);// Replace with your Cloudinary upload preset


                return fetch(`https://api.cloudinary.com/v1_1/${cldNme}/image/upload`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => data.secure_url);
            });

            const imageUrls = await Promise.all(uploadPromises);
            setUploadedImagesgoodsImagesLinks(imageUrls);
            // setImageLinks(imageUrls); // Save the uploaded image links to imageLinks state
            setFormData({
                ...formData,
                goodsImagesLinks: imageUrls,
            });
            toast.success('Image(s) uploaded successfully', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    const handleImageLinksChangegoodsImagesLinks = (acceptedFiles) => {
        handleImageUploadgoodsImagesLinks(acceptedFiles);
    };



    const [uploadedImagesclientReceptImagesLinks, setUploadedImagesclientReceptImagesLinks] = useState([]);

    


    const handleImageUploadclientReceptImagesLinks = async (files) => {
        try {
            const uploadPromises = files.map((file) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', 'tsyvx1hr'); // Replace with your Cloudinary upload preset

                return fetch(`https://api.cloudinary.com/v1_1/${cldNme}/image/upload`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => data.secure_url);
            });

            const imageUrls = await Promise.all(uploadPromises);
            setUploadedImagesclientReceptImagesLinks(imageUrls);
            // setImageLinks(imageUrls); // Save the uploaded image links to imageLinks state
            setFormData({
                ...formData,
                clientReceptImagesLinks: imageUrls,
            });
            toast.success('Image(s) uploaded successfully', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    const handleImageLinksChangeclientReceptImagesLinks = (acceptedFiles) => {
        handleImageUploadclientReceptImagesLinks(acceptedFiles);
    };





    const [uploadedImagesforwarderReceptImagesLinks, setUploadedImagesforwarderReceptImagesLinks] = useState([]);




    const handleImageUploadforwarderReceptImagesLinks = async (files) => {
        try {
            const uploadPromises = files.map((file) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', 'tsyvx1hr'); // Replace with your Cloudinary upload preset

                return fetch(`https://api.cloudinary.com/v1_1/${cldNme}/image/upload`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => data.secure_url);
            });

            const imageUrls = await Promise.all(uploadPromises);
            setUploadedImagesforwarderReceptImagesLinks(imageUrls);
            // setImageLinks(imageUrls); // Save the uploaded image links to imageLinks state
            setFormData({
                ...formData,
                forwarderReceptImagesLinks: imageUrls,
            });
            toast.success('Image(s) uploaded successfully', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    const handleImageLinksChangeforwarderReceptImagesLinks = (acceptedFiles) => {
        handleImageUploadforwarderReceptImagesLinks(acceptedFiles);
    };




    // const labelStyle = {
    //     transform: filterStatus !== '' || filterType !== '' ? 'translate(7px, -7px)' : 'translate(7px, -7px)',
    //     fontSize: filterStatus !== '' || filterType !== '' ? 12 : 12,
    //     backgroundColor: 'white'
    //     // Add any other styles you need to adjust
    // };
    // style = { labelStyle }



    const handleGoToGoodsTableClick = () => {
        navigate('/goodsTable', { replace: true });
    };

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData, [name]: value };

        // Calculate clientPaidAmount based on the changed input field
        if (name === 'goodsTotalCBM' || name === 'pricePerCMB') {
            if (formData.goodsShippingMode === 'By Sea') {
                const totalCMB = parseFloat(updatedFormData.goodsTotalCBM || 0);
                const pricePerCMB = parseFloat(updatedFormData.pricePerCMB || 0);
                updatedFormData.clientPaidAmount = totalCMB * pricePerCMB;
            }
        } else if (name === 'goodsTotalWeight' || name === 'pricePerKg') {
            if (formData.goodsShippingMode === 'By Flight') {
                const totalKg = parseFloat(updatedFormData.goodsTotalWeight || 0);
                const pricePerKg = parseFloat(updatedFormData.pricePerKg || 0);
                updatedFormData.clientPaidAmount = totalKg * pricePerKg;
            }
        }


        // Calculate Supplier PaidAmount based on the changed input field
        if (name === 'goodsTotalCBM' || name === 'SupplierpricePerCMB') {
            if (formData.goodsShippingMode === 'By Sea') {
                const totalCMB = parseFloat(updatedFormData.goodsTotalCBM || 0);
                const SupplierpricePerCMB = parseFloat(updatedFormData.SupplierpricePerCMB || 0);
                updatedFormData.forwarderPaymentAmount = totalCMB * SupplierpricePerCMB;
            }
        } else if (name === 'SuplierGoodsTotalWeight' || name === 'SupplierpricePerKg') {
            if (formData.goodsShippingMode === 'By Flight') {
                const totalKg = parseFloat(updatedFormData.SuplierGoodsTotalWeight || 0);
                const SupplierpricePerKg = parseFloat(updatedFormData.SupplierpricePerKg || 0);
                updatedFormData.forwarderPaymentAmount = totalKg * SupplierpricePerKg;
            }
        }

        setFormData(updatedFormData);
    };



        const calculateClientPaidAmount = () => {
        if (formData.goodsShippingMode === 'By Sea') {
            const totalCMB = parseFloat(formData.goodsTotalCBM);
            const pricePerCMB = parseFloat(formData.pricePerCMB);
            const clientPaidAmount = totalCMB * pricePerCMB;
            setFormData({ ...formData, clientPaidAmount });
        } else if (formData.goodsShippingMode === 'By Flight') {
            const totalKg = parseFloat(formData.goodsTotalWeight);
            const pricePerKg = parseFloat(formData.pricePerKg);
            const clientPaidAmount = totalKg * pricePerKg;
            setFormData({ ...formData, clientPaidAmount });
        }
    };


    const calculateSupplierPaidAmount = () => {
        if (formData.goodsShippingMode === 'By Sea') {
            const totalCMB = parseFloat(formData.goodsTotalCBM);
            const SupplierpricePerCMB = parseFloat(formData.SupplierpricePerCMB);
            const forwarderPaymentAmount = totalCMB * SupplierpricePerCMB;
            setFormData({ ...formData, forwarderPaymentAmount });
        } else if (formData.goodsShippingMode === 'By Flight') {
            const totalKg = parseFloat(formData.goodsTotalWeight);
            const SupplierpricePerKg = parseFloat(formData.SupplierpricePerKg);
            const forwarderPaymentAmount = totalKg * SupplierpricePerKg;
            setFormData({ ...formData, forwarderPaymentAmount });
        }
    };

    function formatCurrency(amount) {
        return amount.toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
        });

    }

    return (
        <Container>

            <Typography variant="h6" style={{ fontSize: '2rem', fontWeight: 'bold' }}>

                 New Goods 🚢✈
            </Typography>
            <form>
                <Grid container spacing={2}>
                    {/* Add form fields here, following the pattern below */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Goods Name *"
                            name="goodsName"
                            value={formData.goodsName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Goods Description *"
                            name="goodsDescription"
                            value={formData.goodsDescription}
                            onChange={handleChange}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        {/* <TextField
                            fullWidth
                            label="Goods goodsCountryOrigin"
                            name="goodsCountryOrigin"
                            value={formData.goodsCountryOrigin}
                            onChange={handleChange}
                        /> */}

                        <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel>Country of Origin  *</InputLabel>
                            <Select
                                label="Goods goodsCountryOrigin"
                                name="goodsCountryOrigin"
                                value={formData.goodsCountryOrigin}
                                onChange={handleChange}
                                fullWidth

                            >
                                <MenuItem value="China">China</MenuItem>
                                <MenuItem value="Guinea">Guinea</MenuItem>

                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        {/* <TextField
                            fullWidth
                            label="goodsCountryDestination"
                            name="goodsCountryDestination"
                            value={formData.goodsCountryDestination}
                            onChange={handleChange}
                        /> */}

                        

                        <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel>Country of Destination * </InputLabel>
                            <Select
                                label="goodsCountryDestination"
                                name="goodsCountryDestination"
                                value={formData.goodsCountryDestination}
                                onChange={handleChange}
                                fullWidth

                            >
                                <MenuItem value="China">China</MenuItem>
                                <MenuItem value="Guinea">Guinea</MenuItem>

                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                    </Grid>

                     <Grid item xs={12}>

                    <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                        <InputLabel>Goods Current Location * </InputLabel>
                        <Select
                            label="goodsCurrentCountrylocation"
                            name="goodsCurrentCountrylocation"
                            value={formData.goodsCurrentCountrylocation}
                            onChange={handleChange}
                            fullWidth

                        >
                            <MenuItem value="China">China</MenuItem>
                            <MenuItem value="Guinea">Guinea</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>



                    <Grid item xs={12}>

                        <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel
                            style={{
                                backgroundColor: 'white',
                            }}
                            >Type of Goods ( Colis) *</InputLabel>
                            <Select
                                fullWidth
                                label="goodsType"
                                name="goodsType"
                                value={formData.goodsType}
                                onChange={handleChange}

                            >
                                <MenuItem value="MCO">MCO</MenuItem>
                                <MenuItem value="FRET">FRET</MenuItem>
                                <MenuItem value="FCL">FCL</MenuItem>
                                <MenuItem value="LCL">LCL</MenuItem>
                                <MenuItem value="VRAC">VRAC</MenuItem>
                                <MenuItem value="OTHER">OTHER</MenuItem>

                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>


                    </Grid>

                    <Grid item xs={ 12 }>

                        <Dropzone onDrop={ handleImageLinksChangegoodsImagesLinks } accept="image/*" multiple>
                            { ({ getRootProps, getInputProps }) => (
                                <div
                                    { ...getRootProps() }
                                    style={ {
                                        border: '1px dashed #ccc',
                                        padding: '20px',
                                        textAlign: 'center',
                                    } }
                                >
                                    <input { ...getInputProps() } />
                                    <p>Click Here , for Photo goodsImagesLinks </p>
                                    { uploadedImagesgoodsImagesLinks.length > 0 && (
                                        <Stack direction="row" spacing={ 1 }>
                                            { uploadedImagesgoodsImagesLinks.map((imageUrl, index) => (
                                                <Image
                                                    key={ index }
                                                    cloudName="ds1hlry5u"
                                                    publicId={ imageUrl }
                                                    width="100"
                                                />
                                            )) }
                                        </Stack>
                                    ) }
                                </div>
                            ) }
                        </Dropzone>

                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Quantity (nombre de colis/cartons/emballage/...)"
                            name="goodsQuantity"
                            value={formData.goodsQuantity}
                            onChange={handleChange}
                        />
                    </Grid>


                  



                    <Grid item xs={ 12 }>

                        <FormControl style={ { marginBottom: '16px', minWidth: '100%' } }>
                            <InputLabel>Shipping Mode *</InputLabel>
                            <Select
                                fullWidth
                                label="goodsShippingMode"
                                name="goodsShippingMode"
                                value={ formData.goodsShippingMode }
                                onChange={ handleChange }

                            >
                                <MenuItem value="By Sea">By Sea 🚢</MenuItem>
                                <MenuItem value="By Flight">By Flight ✈</MenuItem>
                                <MenuItem value="By Road">By Road 🚛</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    { formData.goodsShippingMode === 'By Sea' && (
                        <>


                            <Grid item xs={ 4 }>
                                <TextField
                                    fullWidth
                                    label="CBM ( Volume )"
                                    name="goodsTotalCBM"
                                    value={ formData.goodsTotalCBM }
                                    onChange={ handleChange }
                                />
                            </Grid>
                            
                            <Grid item xs={ 4 }>
                                <TextField
                                    fullWidth
                                    label="Price per CMB"
                                    name="pricePerCMB"
                                    value={ formData.pricePerCMB }
                                    onChange={ handleChange }
                                />
                                
                            </Grid>
                        </>
                    ) }

                    { formData.goodsShippingMode === 'By Flight' && (
                        <>

                            <Grid item xs={ 4 }>
                                <TextField
                                    fullWidth
                                    label="Weight (KG)"
                                    name="goodsTotalWeight"
                                    value={ formData.goodsTotalWeight }
                                    onChange={ handleChange }
                                />
                            </Grid>


                      
                            <Grid item xs={ 4 }>
                                <TextField
                                    fullWidth
                                    label="Price per Kg"
                                    name="pricePerKg"
                                    value={ formData.pricePerKg }
                                    onChange={ handleChange }
                                />
                             
                            </Grid>
                        </>
                    ) }

                    {/* Display and calculate clientPaidAmount */ }
                    <Grid item xs={ 4 }>
                        <TextField
                            disabled
                            fullWidth
                            label="client Paid Amount"
                            name="clientPaidAmount"
                            value={ formData.clientPaidAmount }
                            onChange={ handleChange }
                            onBlur={ calculateClientPaidAmount }
                        />
                        <div style={ { fontWeight: 'bold' } } >
                            { formatCurrency(formData.clientPaidAmount) }
                        </div>
                    </Grid>


             

                    {/* Common fields */ }
                    <Grid item xs={ 12 }>
                        <TextField
                            fullWidth
                            label="client Name * "
                            name="clientName"
                            value={ formData.clientName }
                            onChange={ handleChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 }>
                        <TextField
                            fullWidth
                            label="client Tel *"
                            name="clientTel"
                            value={ formData.clientTel }
                            onChange={ handleChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 }>
                        <TextField
                            fullWidth
                            label="client Address "
                            name="clientAddress"
                            value={ formData.clientAddress }
                            onChange={ handleChange }
                        />
                    </Grid>


               



                    <Grid item xs={12}>
            

                        <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel 
                                style={{
                                    backgroundColor: 'white',
                                    padding: '0px 12px 0 12px'
                                }}
                            >client Payment Status * </InputLabel>
                            <Select
                                label="clientPaymentStatus"
                                name="clientPaymentStatus"
                                value={formData.clientPaymentStatus}
                                onChange={handleChange}
                                fullWidth

                            >
                                <MenuItem value="Not-Paid">Not-Paid</MenuItem>
                                <MenuItem value="Paid">Paid</MenuItem>

                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>

                    </Grid>


                    <Grid item xs={12}>


                        <Dropzone onDrop={handleImageLinksChangeclientReceptImagesLinks} accept="image/*" multiple>
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: '1px dashed #ccc',
                                        padding: '20px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    <p>Click Here , for Photo client Receipt  </p>
                                    {uploadedImagesclientReceptImagesLinks.length > 0 && (
                                        <Stack direction="row" spacing={1}>
                                            {uploadedImagesclientReceptImagesLinks.map((imageUrl, index) => (
                                                <Image
                                                    key={index}
                                                    cloudName="ds1hlry5u"
                                                    publicId={imageUrl}
                                                    width="100"
                                                />
                                            ))}
                                        </Stack>
                                    )}
                                </div>
                            )}
                        </Dropzone>


                    </Grid>


                    <Grid item xs={12}>
           
                        <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel 
                            
                                style={{
                                    backgroundColor: 'white',
                                    padding: '0px 12px 0 12px'
                                }}> Colis status * </InputLabel>
                            <Select
                                label="status"
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                                fullWidth

                            >
                                <MenuItem value="received in China warehouse">received in China warehouse</MenuItem>
                                <MenuItem value="on the way">on the way</MenuItem>
                                <MenuItem value="under CC">under CC</MenuItem>
                                <MenuItem value="In Guinea warehouse">In Guinea warehouse</MenuItem>
                                <MenuItem value="ready">ready</MenuItem>
                                <MenuItem value="Received By Client">Received By Client</MenuItem>


                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>

                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="forwarder Name *"
                            name="forwarderName"
                            value={formData.forwarderName}
                            onChange={handleChange}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="forwarder Tel *"
                            name="forwarderTel"
                            value={formData.forwarderTel}
                            onChange={handleChange}
                        />
                    </Grid>


                    {/* <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="forwarderPaymentAmount"
                            name="forwarderPaymentAmount"
                            value={formData.forwarderPaymentAmount}
                            onChange={handleChange}
                        />
                    </Grid> */}

                    { formData.goodsShippingMode === 'By Sea' && (
                        <>


                            {/* <Grid item xs={ 12 }>
                                <TextField
                                    fullWidth
                                    label="CBM ( Volume )"
                                    name="goodsTotalCBM"
                                    value={ formData.goodsTotalCBM }
                                    onChange={ handleChange }
                                />
                            </Grid> */}

                            <Grid item xs={ 4 }>
                                <TextField
                                    fullWidth
                                    label="Price per CMB"
                                    name="SupplierpricePerCMB"
                                    value={ formData.SupplierpricePerCMB }
                                    onChange={ handleChange }
                                />

                            </Grid>
                        </>
                    ) }

                    { formData.goodsShippingMode === 'By Flight' && (
                        <>

                            <Grid item xs={ 12 }>
                                <TextField
                                    fullWidth
                                    label="Weight (KG)"
                                    name="SuplierGoodsTotalWeight"
                                    value={ formData.SuplierGoodsTotalWeight }
                                    onChange={ handleChange }
                                />
                            </Grid>



                            <Grid item xs={ 6 }>
                                <TextField
                                    fullWidth
                                    label="Price per Kg"
                                    name="SupplierpricePerKg"
                                    value={ formData.SupplierpricePerKg }
                                    onChange={ handleChange }
                                />

                            </Grid>
                        </>
                    ) }

                    {/* Display and calculate clientPaidAmount */ }
                    <Grid item xs={ 6 }>
                        <TextField
                            disabled
                            fullWidth
                            label="Supplier Paid Amount"
                            name="forwarderPaymentAmount"
                            value={ formData.forwarderPaymentAmount }
                            onChange={ handleChange }
                            onBlur={ calculateSupplierPaidAmount }
                        />
                        <div style={ { fontWeight: 'bold' } } >
                            { formatCurrency(formData.forwarderPaymentAmount) }
                        </div>
                    </Grid>


                    <Grid item xs={12}>

                        <FormControl style={{ marginBottom: '16px', minWidth: '100%' }}>
                            <InputLabel>forwarder Payement Status  * </InputLabel>
                            <Select
                                label="forwarder Payement Status"
                                name="forwarderPayementStatus"
                                value={formData.forwarderPayementStatus}
                                onChange={handleChange}
                                fullWidth

                            >
                                <MenuItem value="Not-Paid">Not-Paid</MenuItem>
                                <MenuItem value="Paid">Paid</MenuItem>

                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12}>

                        <Dropzone onDrop={handleImageLinksChangeforwarderReceptImagesLinks} accept="image/*" multiple>
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: '1px dashed #ccc',
                                        padding: '20px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    <p>Click Here , for Photo forwarder Payement Receipt </p>
                                    {uploadedImagesforwarderReceptImagesLinks.length > 0 && (
                                        <Stack direction="row" spacing={1}>
                                            {uploadedImagesforwarderReceptImagesLinks.map((imageUrl, index) => (
                                                <Image
                                                    key={index}
                                                    cloudName="ds1hlry5u"
                                                    publicId={imageUrl}
                                                    width="100"
                                                />
                                            ))}
                                        </Stack>
                                    )}
                                </div>
                            )}
                        </Dropzone>
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="admin Comment"
                            name="adminComment"
                            value={formData.adminComment}
                            onChange={handleChange}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="forwarder Comment"
                            name="forwarderComment"
                            value={formData.forwarderComment}
                            onChange={handleChange}
                        />
                    </Grid>


                  


                    <Grid item xs={12}>
                      
                        <p style={{ margin: "0px" }} > Collected Date in Warehouse </p>
                        <TextField
                            type="date"
                            // label="delivery Date"
                            name="receptionInWareHouseDate"
                            value={formData.receptionInWareHouseDate}

                            onChange={handleChange}


                            style={{ minWidth: '120px' }} // Set a minimum width
                        />
                    </Grid>


                    <Grid item xs={12}>
         
                        <p style={{ margin: "0px" }} > Expedition Date </p>
                        <TextField
                            type="date"
                            // label="reception Date"
                            name="expeditionDate"
                            value={formData.expeditionDate}

                            onChange={handleChange}


                            style={{ minWidth: '120px' }} // Set a minimum width
                        />
                    </Grid>


 
                </Grid>

               <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: '20px', position: 'relative' }} // Position relative for CircularProgress
                    disabled={isSubmitting} // Disable button while submitting
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" /> // Show CircularProgress while submitting
                    ) : (
                        'Submit'
                    )}
                </Button>
            </form>
            <ToastContainer position="top-center" autoClose={5000} />


            <Fab color="secondary" size="small"
                style={{
                    position: 'fixed', top: '90px', left: 'calc(50% - 50px)',
                    zIndex: 999, transform: 'translateX(-50%)',
                    background: 'linear-gradient(90deg, lightcoral, blue)',
                    marginRight: '10px', borderRadius: '50%'
                }}
                onClick={() => handleGoToGoodsTableClick()}>
                <ListIcon />
            </Fab>


        </Container>
    );
}

export default GoodsForm;
