import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Container, Grid, TextField, Button, Typography, Paper, Box, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import './frontend/css/Login.css';

const App = () => {
    const [tel, setTel] = useState('');
    const [secretCode, setSecretCode] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoginForm, setIsLoginForm] = useState(true);
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!tel || !secretCode) {
            toast.error('Please fill in all required fields', {
                position: 'top-center',
                autoClose: 5000,
            });
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, {
                tel,
                secretCode,
            });

            const { userToken, userRole, userName, userType, userSpecialRole, imageProfile, userID, userID2 } = response.data;

            localStorage.setItem('token', userToken);
            localStorage.setItem('userRole', userRole);
            localStorage.setItem('userTel', tel);
            localStorage.setItem('userType', userType);
            localStorage.setItem('userName', userName);
            localStorage.setItem('userSpecialRole', userSpecialRole);
            localStorage.setItem('imageProfile', imageProfile);
            localStorage.setItem('userID', userID);
            localStorage.setItem('userID2', userID2);

            toast.success('Login Successfully', {
                position: 'top-center',
                autoClose: 5000,
            });

            setTimeout(() => {
                const routeMapping = {
                    admin: '/dashboard',
                    commercial: '/goodsTable',
                    hr: '/adminSettings',
                    sender: '/goodsTableForSender',
                    client: '/goodsTableForUser',
                };

                const destinationRoute = routeMapping[userRole] || '/dashboard';
                window.location.href = `${destinationRoute}`;
            }, 3000);

        } catch (error) {
            setIsSubmitting(false);
            toast.error('Password or Phone wrong/ or Network issue', {
                position: 'top-center',
                autoClose: 5000,
            });
            console.error('Login failed:', error);
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!name || !tel || !secretCode) {
            toast.error('Please fill in all fields.');
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users`, {
                tel,
                secretCode,
                name,email
            });

            const token = response.data.userToken;
            const userRole = response.data.userRole;

            localStorage.setItem('token', token);
            localStorage.setItem('userRole', userRole);
            localStorage.setItem('userTel', tel);

            toast.success('Register Successfully', {
                position: 'top-center',
                autoClose: 5000,
            });

            setTimeout(() => {
                setIsSubmitting(false);
                setIsLoginForm(true);
            }, 4000);

        } catch (error) {
            toast.error(`Register Failed try again ${error.response.data.error} `, {
                position: 'top-center',
                autoClose: 5000,
            });
            setIsSubmitting(false);
            console.error('Register failed:', error);
        }
    };

    const toggleForm = () => {
        setIsLoginForm(!isLoginForm);
    };

    return (
        <div
     
        className="login-background">
            <Container 
            style={{
                marginTop: '40px'
            }}
            component="main" maxWidth="md" className="gradient-form">
                <Paper elevation={ 3 } className="login-container">
                    <Grid container>
                        <Grid item xs={ 12 } md={ 6 } className="login-form">
                            <Box
                                component="form"
                                onSubmit={ isLoginForm ? handleSubmit : handleRegister }
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                p={ 3 }
                            >
                               <a href='/'> <img
                                    src="https://res.cloudinary.com/dw8tpsayc/image/upload/v1721482362/pjjkpzll9xq4qyq1y6yt.png"
                                    alt="Logo"
                                    className="logo2"
                                /></a>

                                { !isLoginForm && (
                                    <>

                                    <TextField
                                    fullWidth
                                    placeholder="Nom et Prénom"
                                    variant="outlined"
                                    margin="normal"
                                    className="input-field"
                                    value={ name }
                                    onChange={ (e) => setName(e.target.value) }
                                    />
                                    
                                    <TextField
                                    fullWidth
                                    placeholder="Email"
                                    variant="outlined"
                                    margin="normal"
                                    className="input-field"
                                    value={ email }
                                    onChange={ (e) => setEmail(e.target.value) }
                                    />
                                    </>
                                ) }

                                <TextField
                                    fullWidth
                                    placeholder="Numéro de téléphone"
                                    variant="outlined"
                                    margin="normal"
                                    className="input-field"
                                    value={ tel }
                                    onChange={ (e) => setTel(e.target.value) }
                                />
                                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="password"
                    type={showPassword ? 'text' : 'password'}
                    value={secretCode}
                    onChange={(e) => setSecretCode(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    className="login-button"
                                    disabled={ isSubmitting }
                                >
                                    { isSubmitting ? <CircularProgress size={ 24 } /> : (isLoginForm ? 'SE CONNECTER' : 'S\'INSCRIRE') }
                                </Button>

                                <Box display="flex" alignItems="center" className="create-account">
                                    <Typography variant="body2">
                                        { isLoginForm ? "Vous n'avez pas de compte ?" : "Vous avez déjà un compte ?" }
                                    </Typography>
                                    <Button
                                        onClick={ toggleForm }
                                        variant="outlined"
                                        color="secondary"
                                        className="create-button"
                                    >
                                        { isLoginForm ? "S'INSCRIRE" : "SE CONNECTER" }
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={ 12 } md={ 6 } className="info-section">
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                p={ 3 }
                                height="100%"
                            >
                                <Typography variant="h5" gutterBottom className="info-title">
                                    Nous sommes plus qu'une simple entreprise de logistique
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <ToastContainer />
            </Container>
        </div>
    );
}

export default App;
