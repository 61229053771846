import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@mui/material';


// import ReactDatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // Import the styles



import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import ImageModal from '../modals/ImageModal';
import UploadModal from '../modals/UploadModal';

function GoodsTable() {
    const [goodsList, setGoodsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [showFilters, setShowFilters] = useState(false);


    
    const initialFilters = {
        status: '',
        startDate: '', // Add startDate and endDate fields
        endDate: '',   // Add startDate and endDate fields
        countryOrigin: '',
        countryDestination: '',
        clientName: '',
        clientTel: '',
        senderName: '',
        senderTel: '',
    };

    const [modalGoodsId, setModalGoodsId] = useState(null); // Store the goodsID for the modal
    const [modalGoodsText, setModalGoodsText] = useState(null); // Store the goodsID for the modal


    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for upload modal


    const handleImageClick = (imageUrl, rowId) => {
        setSelectedImage(imageUrl);
        setSelectedRowId(rowId);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        setSelectedRowId(null);
    };


    const [filters, setFilters] = useState(initialFilters);

    const [isEditing, setIsEditing] = useState(false);
    const [editedGoods, setEditedGoods] = useState({});

    const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);

    const toggleEdit = (goodsId) => {
        setCurrentlyEditedRow(goodsId === currentlyEditedRow ? null : goodsId);
        setEditedGoods({ ...editedGoods, [goodsId]: {} });
    };

    const handleEditChange = (goodsId, field, value) => {
        setEditedGoods((prevEditedGoods) => ({
            ...prevEditedGoods,
            [goodsId]: {
                ...prevEditedGoods[goodsId],
                [field]: value,
            },
        }));
    };

    // Function to cancel the edit and revert to the original values
    const cancelEdit = (rowId) => {
        // Remove the row from the editedGoods state
        const newEditedGoods = { ...editedGoods };
        delete newEditedGoods[rowId];
        setEditedGoods(newEditedGoods);

        // Reset the currentlyEditedRow state
        setCurrentlyEditedRow(null);
    };

    const handleDateChange = (goodsId, field, date) => {
        setEditedGoods((prevEditedGoods) => ({
            ...prevEditedGoods,
            [goodsId]: {
                ...prevEditedGoods[goodsId],
                [field]: date,
            },
        }));
    };

    const testID = (goodsID) => {
        console.log('testID');
        console.log(goodsID);
    };
    const handleUploadButtonClick = (goodsID,targetImageTitle) => {
        setModalGoodsId(goodsID); // Set the goodsID for the modal
        setModalGoodsText(targetImageTitle); //
        setIsUploadModalOpen(true); // Open the upload modal
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false); // Close the upload modal
    };


    const saveEditedGoods = async (goodsId) => {
        const authToken = localStorage.getItem('token');
        const editedData = editedGoods[goodsId];

        console.log(editedData); // Log the data before the asynchronous update
        console.log(goodsId); // Log the data before the asynchronous update

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/goods-tracking/updateByAdmin/${goodsId}`, editedData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            // Update the local state with the edited values
            setGoodsList((prevGoodsList) =>
                prevGoodsList.map((goods) =>
                    goods._id === goodsId ? { ...goods, ...editedData } : goods
                )
            );

            // Toggle off editing mode
            toggleEdit();
        } catch (error) {
            console.error('Error updating goods:', error);
        }
    };




    // Function to reset filters to their initial state
    const handleResetFilters = () => {
        setFilters(initialFilters);
    };

    useEffect(() => {
        const authToken = localStorage.getItem('token');

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/goods-tracking/usergoods/displayAll`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                if (response.status === 200) {
                    // Format the dates before setting the goodsList
                    const formattedGoodsList = response.data.map((goods) => ({
                        ...goods,
                        date: goods.date ? new Date(goods.date).toLocaleDateString() : '',
                        receptionDate: goods.receptionDate ? new Date(goods.receptionDate).toLocaleDateString() : '',
                        deliveryDate: goods.deliveryDate ? new Date(goods.deliveryDate).toLocaleDateString() : '',
                    }));

                    setGoodsList(formattedGoodsList);
                    setIsLoading(false);
                } else {
                    console.error('Error fetching goods:', response.statusText);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching goods:', error);
                setIsLoading(false);

                window.location.href = '/login';

                
            }
        };

        fetchData();

    }, []);



    // const handleFilterChange = (event) => {
    //     const { name, value } = event.target;
    //     setFilters({ ...filters, [name]: value });
    //     console.log(filters._id); // Check the value of _id in the console
    // };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;

        // Convert value to the same data type as _id (e.g., parse it as a string or number)
        const parsedValue = name === '_id' ? value.trim() : value;

        setFilters({ ...filters, [name]: parsedValue });
        console.log(filters._id); // Check the value of _id in the console
    };
    const filteredGoods = goodsList.filter((goods) => {
        const {
            _id,
            status,
            startDate,
            endDate,
            countryOrigin,
            countryDestination,
            clientName,
            clientTel,
            senderName,
            senderTel,
        } = filters;

        const goodsDate = new Date(goods.date);

        // Check if _id is provided in filters and if it is included in the goods._id as a substring
        const idFilterMatch = !_id || goods._id.toString().includes(_id.toString());

        return (
            idFilterMatch &&
            (status === '' || goods.status === status) &&
            (!startDate || goodsDate >= new Date(startDate)) &&
            (!endDate || goodsDate <= new Date(endDate)) &&
            (countryOrigin === '' || goods.goodsCountryOrigin === countryOrigin) &&
            (countryDestination === '' || goods.goodsCountryDestination === countryDestination) &&
            (clientName === '' || goods.clientName.includes(clientName)) &&
            (clientTel === '' || goods.clientTel.includes(clientTel)) &&
            (senderName === '' || goods.senderName.includes(senderName)) &&
            (senderTel === '' || goods.senderTel.includes(senderTel))
        );
    });



    if (isLoading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
        );
    }



    const generateExcelData = () => {
        const data = [];
        data.push([
            'Goods ID',
            'Goods Name',
            'Goods Status',
            'Goods Description',
            'Goods Type',
            'Goods Quantity',
            'Country Origin',
            'Country Destination',
            'Total Weight',
            'Total CBM',
            'Shipping Mode',
            'Current Location',
            'Client Name',
            'Client Tel',
            'Client Address',
            'Client Paid Amount',
            'Client Payment Status',
            'Sender Name',
            'Sender Tel',
            'Sender Payment Amount',
            'Sender Payment Status',
            'Admin Comment',
            'Sender Comment',
            'Client',
            'Date',
            'Delivery Date',
            'Reception Date',
        ]);

        filteredGoods.forEach((goods) => {
            data.push([
                goods._id.slice(-6), // Extract the last 6 digits of the ID
                goods.goodsName,
                goods.status,
                goods.goodsDescription,
                goods.goodsType,
                goods.goodsQuantity,
                goods.goodsCountryOrigin,
                goods.goodsCountryDestination,
                goods.goodsTotalWeight,
                goods.goodsTotalCBM,
                goods.goodsShippingMode,
                goods.goodsCurrentCountrylocation,
                goods.clientName,
                goods.clientTel,
                goods.clientAddress,
                goods.clientPaidtAmount,
                goods.clientPaymentStatus,
                goods.senderName,
                goods.senderTel,
                goods.senderPaymentAmount,
                goods.senderPayementStatus,
                goods.adminComment,
                goods.senderComment,
                goods.client,
                goods.date,

                // this is what i add

                goods.deliveredToClientDate,
                goods.receptionInWareHouseDate,
                goods.expeditionDate,

                // end
                goods.deliveryDate,
                goods.receptionDate,
            ]);
        });

        return data;
    };

    const handleDownloadExcel = () => {
        const data = generateExcelData();
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Expenses');
        XLSX.writeFile(wb, 'expenses.xlsx');
    
    };

    // function handleDownloadExcel() {
    //     const data = generateExcelData();
    //     const ws = XLSX.utils.aoa_to_sheet(data);
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'Expenses');
    //     XLSX.writeFile(wb, 'expenses.xlsx');
    // }

    

    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };

    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };



    

    return (
        <div style={{ margin: '0 auto', maxWidth: '1200px' }}>


            <Button
                variant="contained"
                onClick={toggleFilters}
                style={{
                    backgroundColor: 'blue',
                    color: 'white',
                    minWidth: '120px',
                    marginBottom: '10px',
                }}
            >
                {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
            {
                showFilters && (
                    <>
                        <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>


                            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                                {/* <InputLabel>Goods ID</InputLabel> */}
                                <TextField
                                    label="Goods ID"
                                    name="_id"
                                    value={filters._id}
                                    onChange={handleFilterChange}
                                />
                            </FormControl>

                            <FormControl style={{ minWidth: '150px', flex: '1' }}>


                                <InputLabel
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '0px 12px 0 12px'
                                    }}
                                >Status</InputLabel>
                                <Select
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="shipping">Shipping</MenuItem>
                                    <MenuItem value="packing">Packing</MenuItem>
                                </Select>
                            </FormControl>




                            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                                <InputLabel
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '0px 12px 0 12px'
                                    }}
                                >Country Origin</InputLabel>
                                <Select
                                    name="countryOrigin"
                                    value={filters.countryOrigin}
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="China">China</MenuItem>
                                    <MenuItem value="Guinea">Guinea</MenuItem>
                                    {/* Add more country options as needed */}
                                </Select>
                            </FormControl>

                            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                                <InputLabel

                                    style={{
                                        backgroundColor: 'white',
                                        padding: '0px 12px 0 12px'
                                    }}
                                >Country Destination</InputLabel>
                                <Select
                                    name="countryDestination"
                                    value={filters.countryDestination}
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="China">China</MenuItem>
                                    <MenuItem value="Guinea">Guinea</MenuItem>
                                    {/* Add more country options as needed */}
                                </Select>
                            </FormControl>








                        </div>

                        <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>

                            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                                <p>Fitler Start Date</p>
                                <TextField
                                    name="startDate"
                                    type="date"
                                    value={filters.startDate}
                                    onChange={handleFilterChange}
                                />
                            </FormControl>

                            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                                <p> Filter End Date</p>
                                <TextField
                                    name="endDate"
                                    type="date"
                                    value={filters.endDate}
                                    onChange={handleFilterChange}
                                />
                            </FormControl>

                        </div>


                        <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: 'darkred', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}
                                onClick={handleResetFilters}
                            >
                                Reset Filters
                            </Button>

                            <Button
                                variant="contained"
                                onClick={handleDownloadExcel}
                                style={{ backgroundColor: 'primary', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}

                            >
                                Download
                            </Button>

                        </div>
                    </>
            )
            }
          


      



           
        <di> <h3>Total {filteredGoods.length} </h3> </di>

            {/* Upload modal */}
            {isUploadModalOpen && (
                <UploadModal goodsID={modalGoodsId} modalGoodsText={modalGoodsText} onClose={handleUploadModalClose} />
            )}
            {selectedImage && (
                <ImageModal imageUrl={selectedImage} onClose={handleCloseModal} />
            )}
        <TableContainer component={Paper}>
            <Table>
                    <TableHead
                        style={{
                            backgroundColor: '#78AEF9',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                            fontWeight: 'bold', // Apply bold font
                        }}
                    >
                    <TableRow>
                            <TableCell 
                            style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Goods ID</TableCell>
                            <TableCell 
                            style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Goods Images</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Goods Name</TableCell>
                      <TableCell 
                      style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Current Location</TableCell>

                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Goods Status</TableCell>
                            <TableCell 
                            style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Client Receipt</TableCell>

                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Goods Description</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Goods Type</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Goods Quantity</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Country Origin</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Country Destination</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Total Weight</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Total CBM</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Shipping Mode</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Client Name</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Client Tel</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Client Address</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Client Paid Amount</TableCell>
                       <TableCell 
                       style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Client Payment Status</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Admin Comment</TableCell>
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Reception Date in warehouse (China)</TableCell>
                        {/* <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Delivery Date</TableCell> */}
                        <TableCell 
                        style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}
            >Reception Date in Guinea</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                       

                        {filteredGoods.map((goods) => (
                          
                            <TableRow key={goods._id}>
                              
                                <TableCell>{goods._id.slice(-6)}</TableCell>
                                {/* <TableCell>{goods._id}</TableCell> */}
                                {/* <TableCell>{goods.goodsImagesLinks}</TableCell> */}
                                <TableCell>
                                    <div style={{ whiteSpace: 'nowrap' }}>
                                        
                                            {goods.goodsImagesLinks.map((imageLink, index) => (

                                                <img
                                                    key={index}
                                                    src={imageLink}
                                                    alt={`Image ${index}`}
                                                    style={{
                                                        display: 'inline-block',
                                                        maxWidth: '43px',
                                                        maxHeight: '43px',
                                                        marginRight: '5px',
                                                        borderRadius: '50px', // Set your desired border radius
                                                        marginTop: '0',      // Remove marginTop
                                                        marginBottom: '0'    // Remove marginBottom
                                                    }}
                                                    onClick={() => handleImageClick(imageLink)}
                                                />

                                            ))}
                                    </div>
                                 
                                </TableCell>
                              



                                <TableCell 
                                
                                style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'}}
                                >

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsName || goods.goodsName}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsName', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsName
                                    )}
                                </TableCell>

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Current Location</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsCurrentCountrylocation || goods.goodsCurrentCountrylocation}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsCurrentCountrylocation', e.target.value)
                                                }
                                            >
                                                <MenuItem value="China">China</MenuItem>
                                                <MenuItem value="Guinea">Guinea</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsCurrentCountrylocation
                                    )}
                                </TableCell>


                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.status || goods.status}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'status', e.target.value)
                                                }
                                            >
                                                <MenuItem value="pending">Pending</MenuItem>
                                                <MenuItem value="shipping">Shipping</MenuItem>
                                                <MenuItem value="packing">Packing</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.status
                                    )}
                                </TableCell>

                                <TableCell>
                                    <div style={{ whiteSpace: 'nowrap' }}>


                                        {goods.clientReceptImagesLinks.map((imageLink, index) => (

                                            <img
                                                key={index}
                                                src={imageLink}
                                                alt={`Image ${index}`}
                                                style={{
                                                    display: 'inline-block',
                                                    maxWidth: '43px',
                                                    maxHeight: '43px',
                                                    marginRight: '5px',
                                                    borderRadius: '50px', // Set your desired border radius
                                                    marginTop: '0',      // Remove marginTop
                                                    marginBottom: '0'    // Remove marginBottom
                                                }}
                                                onClick={() => handleImageClick(imageLink)}
                                            />

                                        ))}
                                    </div>

                                </TableCell>
                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsDescription ||  goods.goodsDescription }
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsDescription', e.target.value)
                                            }
                                        />
                                    ) : (
                                            goods.goodsDescription
                                    )}
                                </TableCell>

                            



                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsType || goods.goodsType}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsType', e.target.value)
                                            }
                                        />
                                    ) : (
                                            goods.goodsType
                                    )}
                                </TableCell>


                                <TableCell
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsQuantity || goods.goodsQuantity}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsQuantity', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsQuantity
                                    )}
                                </TableCell>


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsCountryOrigin || goods.goodsCountryOrigin}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsCountryOrigin', e.target.value)
                                                }
                                            >
                                                <MenuItem value="China">China</MenuItem>
                                                <MenuItem value="Guinea">Guinea</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsCountryOrigin
                                    )}
                                </TableCell>


                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsCountryDestination || goods.goodsCountryDestination}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsCountryDestination', e.target.value)
                                                }
                                            >
                                                <MenuItem value="China">China</MenuItem>
                                                <MenuItem value="Guinea">Guinea</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsCountryDestination
                                    )}
                                </TableCell>


                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsTotalWeight || goods.goodsTotalWeight}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsTotalWeight', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsTotalWeight
                                    )}
                                </TableCell>


                                <TableCell
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsTotalCBM || goods.goodsTotalCBM}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsTotalCBM', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.goodsTotalCBM
                                    )}
                                </TableCell>

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Shipping Mode</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.goodsShippingMode || goods.goodsShippingMode}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'goodsShippingMode', e.target.value)
                                                }
                                            >
                                                <MenuItem value="Boat">Boat</MenuItem>
                                                <MenuItem value="Flight">Flight</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.goodsShippingMode
                                    )}
                                </TableCell>


                               

                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.clientName || goods.clientName}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'clientName', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.clientName
                                    )}
                                </TableCell>

                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.clientTel || goods.clientTel}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'clientTel', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.clientTel
                                    )}
                                </TableCell>

                                <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.clientAddress || goods.clientAddress}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'clientAddress', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.clientAddress
                                    )}
                                </TableCell>

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>


                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.clientPaidtAmount || goods.clientPaidtAmount}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'clientPaidtAmount', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.clientPaidtAmount
                                    )}
                                </TableCell>

                                <TableCell>
                                    {currentlyEditedRow === goods._id ? (
                                        <FormControl>
                                            <InputLabel>Paid ?</InputLabel>
                                            <Select
                                                value={editedGoods[goods._id]?.clientPaymentStatus || goods.clientPaymentStatus}
                                                onChange={(e) =>
                                                    handleEditChange(goods._id, 'clientPaymentStatus', e.target.value)
                                                }
                                            >
                                                <MenuItem value="Not-Paid">Not-Paid</MenuItem>
                                                <MenuItem value="Paid">Paid</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        goods.clientPaymentStatus
                                    )}
                                </TableCell>

                              

                             

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.adminComment || goods.adminComment}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'adminComment', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.adminComment
                                    )}
                                </TableCell>

                                


                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}>

                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.receptionInWareHouseDate || goods.receptionInWareHouseDate}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'receptionInWareHouseDate', e.target.value)
                                            }
                                        />
                                    ) : (
                                        formatDateToFrench(goods.receptionInWareHouseDate)
                                    )}
                                </TableCell>

                                {/* <TableCell 
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.deliveryDate || goods.deliveryDate}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'deliveryDate', e.target.value)
                                            }
                                        />
                                    ) : (
                                        goods.deliveryDate
                                    )}
                                </TableCell> */}

                                <TableCell 
                                
                                    style={{
                                        whiteSpace: 'nowrap', // Prevent content from wrapping
                                        overflow: 'hidden',   // Hide overflowing content
                                        textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                        minWidth: '100px',   // Set a minimum width for the cell
                                        width: 'auto'
                                    }}
                                    
                                    >
                                    {currentlyEditedRow === goods._id ? (
                                        <TextField
                                            value={editedGoods[goods._id]?.goodsCreationdate || goods.goodsCreationdate}
                                            onChange={(e) =>
                                                handleEditChange(goods._id, 'goodsCreationdate', e.target.value)
                                            }
                                        />
                                    ) : (
                                        formatDateToFrench(goods.goodsCreationdate)

                                    )}
                                </TableCell>
                             
                            </TableRow>
                        ))}
                      
                </TableBody>
                
            </Table>
              
        </TableContainer>
      
        </div>
    );
}

export default GoodsTable;